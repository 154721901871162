//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getorderTypeLabel, _getorderList,_getmtOrderNumer } from "@/api/maintenance";
import Table from "./table.vue";

export default {
  components: {
    Table,
  },
  data() {
    return {
       pageSize:10,//没页几条
      current: 1,//当前页数
      total:0,//总条数
      editableTabsValue:"30",
      typesa:"",
      list:[],
      formInline: {
        orderId: "",
      },
      options: [
        {
          value: "选项1",
          label: "启用",
        },
        {
          value: "选项2",
          label: "禁用",
        },
      ],
      gdatList: [],
      tableData: [],
      activeName: ""
    };
  },
  created() {
   this.getorderinfo({ type:this.$route.query.selected?this.$route.query.selected:"30",pageSize:this.pageSize,current:this.current})
   this.getorderTypeLabel();
  },
  mounted() {
  this.editableTabsValue = this.$route.query.selected
    ? this.$route.query.selected
    : "30";
  },
  methods: {
    getorderinfo(from){
       _getorderList(from).then((res) => {
       this.tableData = res.data.data;
       this.total = res.data.total
       this.tableData.filter((item)=>{
         item.progress  = item.currentCount + "/" + item.count
       })
    });
    },
        // 获取标签状态
    getorderTypeLabel() {
      _getorderTypeLabel({}).then((res)=>{
        if(res.code == "1"){
        this.getmtOrderNumer()
        this.gdatList = res.data;
        }
      })
    },
    // 小红点的数据
    getmtOrderNumer(){
      _getmtOrderNumer({}).then((res)=>{
       let aaa =res.data;
      if (aaa.length > 0) {
        this.list = this.gdatList.reduce((pre, cur) => {
        let target = pre.find((ee) => ee.orderStatus == cur.id);
        if (target) {
          Object.assign(target, cur);
        } else {
          pre.push(cur);
        }
        return pre;
      }, aaa);
    }else{
      this.list = this.gdatList
    }
    })
    },
    query(){
      let from = {
        type:this.editableTabsValue,
        ...this.formInline
      }
      this.getorderinfo(from)
    },

    // 标签的切换状态
     handleClick(tab) {
       let from = { type:tab.name,...this.formInline }
        this.getorderinfo(from)
      },
     handleSizeChange(val) {
        this.current = 1
      this.pageSize = val
       let from = {
        current:this.current,
        page:this.pageSize,
        type:this.editableTabsValue,
        ...this.formInline
        }
      this.getorderinfo(from)
     },
    handleCurrentChange(val) {
      this.current = val
      let from = {
        current:this.current,
        page:this.pageSize,
        type:this.editableTabsValue,
        ...this.formInline
        }
      this.getorderinfo(from)
    },
  },
};
