//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getorderinfo, _getorderNumber } from '@/api/disabilities.js'
import { _getcode } from '@/api/organizational'
import OrderTable from './OrderTable.vue'

export default {
  components: {
    OrderTable,
  },
  data() {
    return {
      editableTabsValue: '10',
      pageSize: 10, //没页几条
      current: 1, //当前页数
      total: 0, //总条数
      formInline: {
        orderId: '',
        reportLevel: '',
        current: this.current,
        page: this.pageSize,
      },
      type: '10', //标签状态值
      options: [],
      gdatList: [],
      list: [],
      tableData: [],
      activeName: '',
    }
  },
  created() {
    this.getcode()
    ;(this.type = this.$route.query.selected
      ? this.$route.query.selected
      : '10'),
      this.getorderinfo({
        type: this.type,
        id: '',
        orderId: '',
        reportLevel: '',
        current: 1,
        page: 10,
      })
  },
  methods: {
    // 查询
    query() {
      let from = {
        orderId: this.formInline.orderId,
        reportLevel: this.formInline.reportLevel,
        current: this.current,
        page: this.pageSize,
        type: this.type,
        id: '',
      }
      this.getorderinfo(from)
    },
    getorderinfo(from) {
      _getorderinfo(from).then(res => {
        console.log(res.data.data)
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    // 代码表
    getcode() {
      let from = {
        para: 'gdzt',
      }
      _getcode(from).then(res => {
        if (res.code == '1') {
          this.getorderNumber()
          this.gdatList = res.data
        }
      })
      // 故障等级
      _getcode({ para: 'gzdj' }).then(res => {
        this.options = res.data
      })
    },
    // 数据表
    getorderNumber() {
      _getorderNumber({}).then(res => {
        let aaa = [...res.data, { num: "", id: "80" }, { num: "", id: "90" }]
        if (aaa.length > 0) {
          let str = [];
         this.gdatList.forEach(item => {
            aaa.forEach(arr => {
              if (arr.id == item.id) {
                str.push({
                  ...item,
                  num: arr.num,
                })
              }
            })
          })
          // console.log(this.list ,str)
          this.list = str
          // this.list = this.gdatList.reduce((pre, cur) => {
          //   console.log(pre, cur)
          //   let target = pre.find(ee => ee.id == cur.id)
          //   if (target) {
          //     Object.assign(target, cur)
          //   } else {
          //     pre.push(cur)
          //   }
          //   return pre
          // }, aaa)
        } else {
          this.list = this.gdatList
        }
      })
    },
    handleClick(tab) {
      this.type = tab.name
      this.current = 1
      this.pageSize = 10
      let from = {
        type: tab.name,
        id: '',
        orderId: this.formInline.orderId,
        reportLevel: this.formInline.reportLevel,
        current: 1,
        page: 10,
      }
      this.getorderNumber()
      this.getorderinfo(from)
    },
    // 分页
    handleSizeChange(val) {
      this.current = 1
      this.pageSize = val
      let from = {
        current: this.current,
        page: this.pageSize,
        type: this.type,
        orderId: this.formInline.orderId,
        reportLevel: this.formInline.reportLevel,
        id: '',
      }
      this.getorderinfo(from)
    },
    handleCurrentChange(val) {
      this.current = val
      let from = {
        current: this.current,
        page: this.pageSize,
        type: this.type,
        orderId: this.formInline.orderId,
        reportLevel: this.formInline.reportLevel,
        id: '',
      }
      this.getorderinfo(from)
    },
  },
  mounted() {
    this.editableTabsValue = this.$route.query.selected
      ? this.$route.query.selected
      : '10'
  },
}
